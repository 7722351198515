.appStoreButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 3em;
  width: 9em;
  border: "1px solid white";
  border-radius: 5;
  background-color: black;
}

.appStoreButtonTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.appStoreButtonHeaderText {
  color: white;
  margin: 0;
  font-size: 0.5em;
  text-align: center;
}

.appStoreButtonText {
  color: white;
}

.amazonText {
  color: orange;
}
