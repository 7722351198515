.servicesContainer {
  position: relative;
  display: flex;
  height: 80vh;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.servicesGrid {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.service {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 3rem;
  background-color: white;
  height: 70%;
  width: 25%;
}

.title {
  font-size: 32px;
}

.subtitle {
  font-size: 20px;
}

.features {
  margin-top: 10%;
}

.points {
  font-size: 18px;
  margin-top: 5%;
}

@media (max-width: 1000px) {
  .servicesGrid {
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }

  .service {
    padding: 2rem;
    height: 25%;
    width: 70%;
  }

  .title {
    font-size: 12px;
    margin: 0;
    padding: 0;
  }

  .subtitle {
    font-size: 10px;
    margin: 0;
  }

  .features {
    margin-top: 0;
  }

  .points {
    font-size: 8px;
    margin-top: 0;
  }
}
