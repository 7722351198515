.footerContainer {
  display: flex;
  position: relative;
  background-color: #2c3e50;
  height: 300px;
}

.footerGrid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  flex: 1;
  margin: 3rem 2rem;
}

.footerRow {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerText {
  color: white;
  text-align: center;
}

.footerTitle {
  color: white;
  text-align: center;
  font-weight: bold;
}

.footerLink {
  position: relative;
  display: flex;
  color: white;
  text-align: center;
  cursor: pointer;
  margin: auto;
}

.footerLinkText {
  text-align: "center";
  flex: 1;
}
