.background {
  position: relative;
  display: flex;
  height: 80vh;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #3498db;
  background-position: center;
}

.titleContainer {
  background-color: #3b4856;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.text {
  color: white;
}

.appStoreButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}
