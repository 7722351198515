.aboutContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 80vh;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.aboutGrid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 80%;
  width: 80%;
  /* grid-template-columns: 1fr 1fr;
  gap: 2rem; */
}

.imageContainer {
  width: 50%;
  height: 100%;
  /* //background-color: #68978c; */
}

.descriptionContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
  min-width: 0;
  max-width: 50%;
}

.title {
  font-size: 32px;
}

.subtitle {
  font-size: 20px;
}

.paragraph {
  font-size: 18px;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .aboutGrid {
    flex-direction: column;
  }

  .imageContainer {
    width: 100%;
    max-height: 50%;
  }

  .descriptionContainer {
    min-width: 100%;
  }

  .title {
    font-size: 20px;
  }

  .subtitle {
    font-size: 16px;
  }

  .paragraph {
    font-size: 14px;
  }
}
