.navContainer {
  position: sticky;
  display: flex;
  background-color: #489bb4;
  padding-left: 20px;
  padding-right: 20px;
  height: 80px;
  top: 0;
  z-index: 10;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navSection {
  display: flex;
  flex: 1;
}

.navlinkContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 70%;
}

.pageLink {
  color: white;
  text-decoration: none;
  height: 100%;
  aspect-ratio: 1 / 1;
}

.account {
  color: white;
  text-decoration: none;
}

.navlink {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  text-align: center;
  margin-bottom: 3px;
}

/* .navlink:active {
  border-bottom: 3px solid green;
} */

.navMenu {
  display: none;
}

@media (max-width: 768px) {
  .navlinkContainer {
    display: none;
  }

  .account {
    display: none;
  }

  .navMenu {
    display: flex;
  }
}
